<template>
  <div class="AutomationsNavigation">
    <ul>
      <li v-for="item in navigationItems" :key="item.id">
        <router-link :to="{ name: item.pathName }">
          <span>{{ item.label }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'AutomationsEditWebhookNavigation',
  data() {
    return {
      navigationItems: [
        { id: 1, label: 'Webhook', pathName: 'AutomationsWebhook' },
        { id: 2, label: 'Histórico', pathName: 'AutomationsLogs' },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.AutomationsNavigation {
  margin-top: 1px;
  background-color: $white;
}

ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-left: 20px;
  overflow-x: scroll;
}

li {
  display: inline-block;
}

a {
  display: block;
  text-decoration: none;
  color: #333;
  height: 54px;
  padding: 0 12px;

  span {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 3px solid transparent;
    color: #6f6f6f;
    font-size: 14px;
    line-height: 21px;
    text-wrap: nowrap;
  }

  &.router-link-active > span {
    border-color: $purple-dark;
    font-weight: bold;
    color: #262626;
  }
}

@media screen and (min-width: 290px) {
  ul {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 767px) {
  ul {
    padding-left: 4px;
  }
}
</style>
