
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'data',
    event: 'change',
  },
})
export default class HsSwitch extends Vue {
  @Prop({ default: false }) data: boolean;

  handleChange(e) {
    this.$emit('change', e.target.checked);
  }
}
