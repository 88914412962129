<template>
  <div>
    <div v-if="isEditing" class="d-flex flex-row">
      <slot></slot>
      <div class="d-flex" :class="[`justify-content-${alignment}`, `align-items-${alignment}`]">
        <div class="d-flex flex-column">
          <slot name="hint"></slot>
        </div>
        <hs-button id="btn-cancel" @click="cancel()" variant="outline-secondary" class="ml-2">
          <hs-icon icon="times"> </hs-icon>
        </hs-button>
        <hs-button id="btn-save" :disabled="!allowSave" @click="save()" variant="primary" class="ml-2">
          <hs-icon icon="check"> </hs-icon>
        </hs-button>
      </div>
    </div>
    <div v-else class="d-flex align-items-center justify-content-start">
      <hs-icon id="text-icon" class="mr-2" v-if="icon" :icon="icon"></hs-icon>
      <p
        data-testid="default-text"
        class="mb-0 default-text tlw-break-all"
        :style="customStyle"
        :class="[...customClass]"
      >
        {{ value }}
      </p>
      <button id="edit-btn" @click="initEditing()" v-b-tooltip.hover.top="$t('actions.edit')" class="btn btn-link">
        <hs-icon icon="pencil" size="16" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hsEditableText',
  props: {
    value: {
      required: true,
      type: String,
    },
    icon: {
      type: String,
    },
    alignment: {
      type: String,
      default: 'end',
      validator: value => ['end', 'start'].includes(value),
    },
    allowSave: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: Array,
      default: () => ['lh-150'],
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
    minLength: {
      type: Number,
    },
  },
  data() {
    return {
      isEditing: false,
      oldValue: '',
    };
  },
  mounted() {
    this.oldValue = this.value;
  },
  methods: {
    initEditing() {
      this.oldValue = this.value;
      this.isEditing = true;
    },
    save() {
      this.$emit('save');
      this.$emit('input', this.value);
      if (this.minLength && this.value.length >= this.minLength) {
        this.isEditing = false;
      }
    },
    cancel() {
      this.$emit('input', this.oldValue);
      this.isEditing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip {
  top: 0;
}
.default-text {
  letter-spacing: -0.2px;
  color: $grey;
}

#edit-btn > i {
  font-size: 28px;
  color: $grey;
}

@media only screen and (min-width: $screen-bs-sm) {
  #edit-btn > i {
    font-size: 14px;
    color: $grey;
  }
}
</style>
