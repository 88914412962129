















































































import { Component, Vue } from 'vue-property-decorator';
import eventService from '@/services/event';
import ToastHelper from '@/shared/helpers/toast';
import { AutomationModel, FilterAutomationModel } from '@/types/automation';
import { getAutomationCategoryName } from '@/libs/automation';
import { AutomationModels } from '@/data/automation_models';
import Features from '@/types/features';

import Loading from '@/components/Loading.vue';
import MButton from '@/shared/components/MButton.vue';
import HsSwitch from '@/components/hsSwitch.vue';
import TrackingHelper from '@/shared/helpers/tracking';

@Component({
  components: {
    MButton,
    Loading,
    HsSwitch,
  },
  methods: {
    getAutomationCategoryName,
  },
})
export default class AutomationsTabModels extends Vue {
  isLoading: boolean = false;
  isCreating: boolean = false;

  filters: string[] = [
    FilterAutomationModel.ALL,
    FilterAutomationModel.CART_RECOVERY,
    FilterAutomationModel.RECURRENCY,
    FilterAutomationModel.INTERACT_WITH_LEADS,
    FilterAutomationModel.NEW_CART_RECOVERY,
  ];

  filterSelected: string = 'all';

  models: AutomationModel[] = AutomationModels;
  selectedModel: null | AutomationModel = null;

  mounted() {
    const { automationModel } = this.$route.query;

    if (automationModel) this.filterSelected = automationModel.toString();
  }

  get hasSparkCheckout(): boolean {
    return this.$FCL.hasSparkCheckout();
  }

  get modelsListByCheckout(): AutomationModel[] {
    const checkout = this.hasSparkCheckout ? Features.SPARK_CHECKOUT : Features.EXPRESS_CHECKOUT;
    return this.models.filter(model => !model.checkout.length || model.checkout.includes(checkout));
  }

  filterBy(modelCategory: string) {
    return (
      this.filterSelected === FilterAutomationModel.ALL ||
      this.filterSelected === modelCategory ||
      modelCategory === 'new_model'
    );
  }

  getCategoryIcon(category: string) {
    const icons = {
      new_cart_recovery: 'shopping-cart',
      all: 'clipboard-list',
      cart_recovery: 'usd-circle',
      upsell: 'sync',
      new_model: 'lightbulb',
      interact_with_leads: 'chart-line',
      recurrency: 'sync',
    };

    return icons[category];
  }

  async createAutomation(model: AutomationModel) {
    try {
      this.isCreating = true;
      this.selectedModel = model;

      const { id } = await eventService.createAction(model.trigger, {
        ...model.action,
        name: model.title,
      });
      TrackingHelper.trackAutomationSelectModel(model.trigger, id);

      const query = ['Webhook', 'HeroWebhook'].includes(model.action.integration) ? {} : { new: 'true' };
      this.$router.push({ name: 'AutomationsEdit', params: { automationId: id.toString() }, query });
    } catch (error) {
      ToastHelper.dangerMessage('Erro ao criar automação.');
    } finally {
      this.isCreating = false;
      this.selectedModel = null;
    }
  }
}
