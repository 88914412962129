<template>
  <div>
    <PageHeader
      :title="$t('automations.edit.title-webhook')"
      :subTitle="$t('automations.edit.subtitle-webhook')"
      :back-route="{}"
      :back-text="$t('automations.edit.back-to')"
      class="tlw-bg-white"
    />

    <Navigation />
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/_structures/PageHeader.vue';
import Navigation from './components/AutomationsEditWebhookNavigation.vue';

export default {
  name: 'AutomationsEditWebhook',
  components: {
    PageHeader,
    Navigation,
  },
  data() {
    return {
      hasHeader: false,
    };
  },
  mounted() {
    this.hasHeader = this.$router.history.current.meta.hasHeader;
  },
};
</script>
