













































































import { Component, Mixins } from 'vue-property-decorator';
import _ from 'lodash';
import ToastHelper from '@/shared/helpers/toast';
import eventService from '@/services/event';

import Loading from '@/components/Loading.vue';
import MButton from '@/shared/components/MButton.vue';
import MSwitch from '@/shared/components/MSwitch.vue';
import Confirm from '@/shared/mixins/Confirm';

import { AutomationsTabs } from '@/libs/automation';
import { Automation } from '@/types/automation';
import { getAutomationCategoryName } from '@/libs/automation';
import TrackingHelper from '@/shared/helpers/tracking';

@Component({
  components: {
    MButton,
    Loading,
    MSwitch,
    ConfirmModal: () => import('@/components/ConfirmModal.vue'),
    AutomationsModalIncompleteEdition: () => import('../../../components/AutomationsModalIncompleteEdition.vue'),
  },
  methods: {
    getAutomationCategoryName,
  },
})
export default class AutomationsTabList extends Mixins(Confirm) {
  automations: Automation[] = [];
  automationCategory: string = '';
  totalAutomations: number = 0;
  isLoading: boolean = false;
  isDeleting: boolean = false;
  selectedAutomation: number | null = null;
  perPage: number = 50;
  fields: any[] = [];
  currentPage: number = 1;
  showConfirmBox!: any;
  AutomationsTabs: any = AutomationsTabs;
  openedIncompleteEditionModal: boolean = false;

  created() {
    this.fields = [
      { key: 'name', label: 'Nome', sortable: true },
      {
        key: 'trigger',
        label: this.$t('automations.list.table.type'),
        sortable: true,
        formatter: (value, key, item) => {
          return this.$t(`automations.triggers.${item.trigger}.name`);
        },
      },
      {
        key: 'integration',
        label: this.$t('automations.list.table.action'),
        sortable: true,
        formatter: (value, key, item) => {
          return this.$t(`automations.actions.${item.integration}.name`);
        },
      },
      {
        key: 'actions',
        label: this.$t('automations.list.table.actions'),
        thClass: 'tlw-text-center',
      },
      {
        key: 'active',
        label: this.$t('automations.list.table.status.title'),
        sortable: true,
        thClass: 'tlw-text-right',
      },
    ];
  }

  async mounted() {
    await this.getAutomations();
  }

  async getAutomations(page: number = 1) {
    this.currentPage = page;
    try {
      this.isLoading = true;

      const { actions, total_count } = await eventService.getActions([
        { key: 'page', value: page },
        { key: 'per_page', value: this.perPage },
      ]);

      const filteredAutomations = this.removeLessonsAutomations(actions);

      this.automations = this.formatDataTable(filteredAutomations);

      this.totalAutomations = total_count;
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('automations.toast.error.get-automations'));
    } finally {
      this.isLoading = false;
    }
  }

  removeLessonsAutomations(automations: Automation[]) {
    return automations.filter(({ event: { trigger } }) => trigger !== 'ended_lesson');
  }

  formatDataTable(actions) {
    return actions.map(a => ({
      id: a.id,
      name: a.name,
      trigger: a.event.trigger,
      integration: a.integration,
      active: a.active,
      settings: { ...a.settings },
      configurations: { ...a.configurations },
      event: { ...a.event },
    }));
  }

  async updateAutomationStatus(automation: Automation) {
    try {
      if (this.automationSettingsIsNull(automation)) return;

      await eventService.updateAction(automation);

      if (automation.active) {
        TrackingHelper.trackAutomationActive('list_automation', automation.id);
      }

      const statusAutomation = automation.active ? 'actived' : 'disabled';
      ToastHelper.successMessage(this.$t(`automations.toast.success.automation-${statusAutomation}`));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('automations.toast.error.active-automation'));
    }
  }

  automationSettingsIsNull({ configurations, settings }: Automation) {
    return _.isEmpty(settings) && _.isEmpty(configurations);
  }

  openModalIncompleteEdition() {
    this.openedIncompleteEditionModal = true;
    this.$bvModal.show('incomplete-edition-modal');
  }

  async deleteAutomation(automation: Automation) {
    try {
      const confirmOptions = {
        contentTitle: this.$t('automations.list.delete-confirm-modal.title'),
        contentDescription: this.$t('automations.list.delete-confirm-modal.subtitle'),
        okTitle: this.$t('automations.list.delete-confirm-modal.btn-ok'),
        cancelTitle: this.$t('automations.list.delete-confirm-modal.btn-cancel'),
        modalClass: 'delete-tag',
        variant: 'cherry',
        icon: 'trash-alt',
      };
      const confirmResponse = await this.showConfirmBox(confirmOptions);
      if (!confirmResponse) return;

      this.isDeleting = true;
      this.selectedAutomation = automation.id;

      await eventService.deleteAction(automation.id);
      const index = this.automations.findIndex(autom => autom.id === automation.id);
      TrackingHelper.trackAutomationDelete(this.automations, this.selectedAutomation);
      this.$delete(this.automations, index);
      this.$root.$emit('bv::refresh::table', 'table');

      ToastHelper.successMessage(this.$t('automations.toast.success.automation-deleted'));
    } catch (error) {
      ToastHelper.dangerMessage(this.$t('automations.toast.error.automation-deleted'));
    } finally {
      this.isDeleting = false;
      this.selectedAutomation = null;
    }
  }

  goToEditAutomation(automation: Automation) {
    if (['Webhook', 'HeroWebhook'].includes(automation.integration)) {
      return this.$router.push({ name: 'AutomationsWebhookEdit', params: { automationId: automation.id.toString() } });
    }

    this.$router.push({ name: 'AutomationsEdit', params: { automationId: automation.id.toString() } });
  }
}
