

























import { Component, Vue } from 'vue-property-decorator';
import PageTabLayout from '@/layout/PageTabLayout.vue';
import PageHeader from '@/components/_structures/PageHeader.vue';
import MButton from '@/shared/components/MButton.vue';
import AutomationsTabDashboard from './AutomationsTabDashboard/index.vue';
import AutomationsTabModels from './AutomationsTabModels/index.vue';
import AutomationsTabList from './AutomationsTabList/index.vue';

import { Tab } from '@/types/tab';
import { AutomationsTabs as Tabs } from '@/libs/automation';

@Component({
  components: {
    PageTabLayout,
    PageHeader,
    MButton,
  },
})
export default class AutomationsTabs extends Vue {
  tabs: Tab[] = [];
  currentTab = this.$route?.query?.tab || Tabs.AUTOMATIONS_LIST_TAB;
  AutomationsTabs: any = Tabs;

  created() {
    this.tabs = [
      // {
      //   id: AutomationsTabs.AUTOMATIONS_DASHBOARD_TAB,
      //   label: this.$t('automations.tabs.dashboard'),
      //   permission: true,
      //   component: AutomationsTabDashboard,
      // },
      {
        id: Tabs.AUTOMATIONS_LIST_TAB,
        label: this.$t('automations.tabs.your-automations'),
        permission: true,
        component: AutomationsTabList,
      },
      {
        id: Tabs.AUTOMATIONS_MODELS_TAB,
        label: this.$t('automations.tabs.models'),
        permission: true,
        component: AutomationsTabModels,
      },
    ];
  }

  get selectedTab() {
    return this.tabs.find(tab => tab.id === this.currentTab) || {};
  }

  mounted() {
    window.scrollTo(0,0)
  }

  setSelectedTab(tab) {
    this.currentTab = tab;
  }
}
